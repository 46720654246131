import React, { useMemo, useState } from 'react';
import {
  Button,
  Form,
  InputText,
  LinkAnchorTo,
  Page,
  PageHeading,
} from 'components';
import { useForm, Controller } from 'react-hook-form';
import { axiosGet, axiosPost } from 'helpers';
import useLinksQuery from '_api/Links';
import { getApiHrefByRel } from 'helpers/api-links-helper';
import Notification from 'components/notification';
import { login } from 'redux/auth/user-auth-slice';
import { useDispatch } from 'react-redux';
import LinksApi from '_api/Links/LinksApi';
import { Typography } from '@mui/material';
import { t } from 'i18next';
import { useSearchParams } from 'react-router-dom';

interface LoginFormInputs {
  Username: string;
  Password: string;
  Rel: string;
}
export interface ErrorShape {
  data: {
    Message: string;
    Errors: {
      [key: string]: string[];
    };
  };
  status: number;
  statusText: '';
}
type LinkResponse = [
  {
    Rel: string;
    Href: string;
  },
];

export type LoginApiRes = {
  data: {
    Links: LinkResponse;
  };
  status: number;
  statusText: string;
};

export type userResponse = {
  Email: string;
  FirstName: string;
  IsEmailVerified: boolean;
  Language: string;
  LastName: string;
  Links: LinkResponse;
};
const LoginPage: React.FC = () => {
  const dispatch = useDispatch();
  const { control, handleSubmit } = useForm<LoginFormInputs>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<ErrorShape | null>(null);
  const { data: endpoints } = useLinksQuery();
  const [searchParams] = useSearchParams();
  const register = searchParams.get('register');
  const loginLink = useMemo(
    () => getApiHrefByRel(endpoints ?? [], 'cookie-auth'),
    [endpoints],
  );

  const onSubmit = async (data: LoginFormInputs) => {
    console.log('starting to submit login data');
    if (loginLink) {
      setLoading(true);
      setError(null);
      try {
        console.log('going to post to login link:', loginLink);
        const res = (await axiosPost(loginLink, data)) as LoginApiRes;
        console.log('response of login link post', res);
        console.log(
          'stringified  resp of login link post',
          JSON.stringify(res),
        );
        const userGetInfoLinkObj = res.data.Links.find(
          (item) => item.Rel === 'user-account',
        );
        if (userGetInfoLinkObj) {
          console.log(
            'user-account link found in response',
            userGetInfoLinkObj,
          );
          const userData = await axiosGet(userGetInfoLinkObj?.Href.toString());
          console.log('userData response arrived and is', userData);
          if (userData?.data) {
            dispatch(login(userData.data as userResponse));
            dispatch(LinksApi.util.invalidateTags(['links']));
          } else {
            console.error('userData?.data was not');
          }
        } else {
          console.error(
            'user get info link not found in response links "user-account" ',
          );
        }
      } catch (error) {
        console.error('got error on login', error);
        const errorObj = error as ErrorShape;
        if (errorObj.status === 400) {
          setError(errorObj);
        }
      } finally {
        setLoading(false);
      }
    } else {
      console.error('login link has vanished');
    }
  };

  return (
    <Page>
      {/* API Toast Error */}
      {error?.data?.Message && (
        <Notification
          isAutoClosing={true}
          onClose={() => {
            setError(null);
          }}
          severity="error"
        >
          {error.data.Message}
        </Notification>
      )}
      <PageHeading heading="Login" />
      {register === 'success' ? (
        <Notification severity="success">
          {t('User has been register successfully.')}
        </Notification>
      ) : register === 'failed' ? (
        <Notification severity="error" variant="filled">
          {t('User has not registered due to some Unexpected Error')}
        </Notification>
      ) : null}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="Username"
          control={control}
          defaultValue=""
          render={() => (
            <InputText
              label={t('Email')}
              type="email"
              control={control}
              name="Username"
              rules={{
                required: t('Please enter email'),
                pattern: {
                  value: /^\S+@\S+$/i,
                  message: t('Invalid email address'),
                },
              }}
            />
          )}
        />
        <Controller
          name="Password"
          control={control}
          defaultValue=""
          render={() => (
            <InputText
              label={t('Password')}
              type="password"
              control={control}
              name="Password"
              rules={{
                required: t('Please enter password'),
                minLength: {
                  value: 8,
                  message: t('Password must be at least 8 characters long'),
                },
              }}
            />
          )}
        />
        <Button type="submit" loading={loading} disabled={loading}>
          {t('Login')}
        </Button>
        <LinkAnchorTo to="/request-reset-password">
          {t('Forgot password?')}
        </LinkAnchorTo>
        <Typography variant="h6" sx={{ letterSpacing: '0', fontSize: '18px' }}>
          {t('Dont have an account?')}
          <LinkAnchorTo to="/register"> {t('Create one here')}</LinkAnchorTo>
        </Typography>
      </Form>
    </Page>
  );
};

export default LoginPage;
